<template>
  <header class="fixed-header lg-header" v-if="$mq != 'sm' && $mq != 'md'" id="main-nav">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand desktop-logo-wrap" href="/">
          <div class="desktop-logo"></div>
          spottySpo
        </a>
        <ul class="navbar-nav ml-auto navbar-md-lg">
          <li class="nav-item" v-if="!current_user.is_guest" style="margin-top: 4px;">
            <a class="btn cta-btn orange btn-small" href="/sport_events/new">
              + {{ I18n.t("sport_events.index.new_button") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="/sport_events" class="nav-link" style="position: relative;">
              {{ I18n.t("side_menu.names.events") }}
              <span class="sport-events-notification-counter" v-if="current_user.possible_events_count > 0"></span>
            </a>
          </li>
          <li class="nav-item">
            <a href="/users" class="nav-link">{{ I18n.t("side_menu.names.users") }}</a>
          </li>
          <li class="nav-item">
            <a href="/pages/about" class="nav-link">{{ I18n.t("side_menu.names.about") }}</a>
          </li>
          <li class="nav-item" v-if="current_user.is_guest" style="display: flex; align-items: center">
            <a href="/users/sign_in" class="btn cta-btn yellow btn-small">{{ I18n.t("side_menu.names.sign_in") }}</a>
          </li>
          <li class="nav-item" v-if="current_user.is_guest">
            <a href="/users/sign_up" class="nav-link">{{ I18n.t("side_menu.names.sign_up") }}</a>
          </li>
          <li class="nav-item dropdown" v-if="!current_user.is_guest">
            <span class="desktop-notification-counter" v-if="unopenedNotificationCount > 0"></span>
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="avatar" :src="imgPath()" height="23" width="23">
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <div class="dropdown-notification-wrap">
                <a class="dropdown-item" href="/notifications">
                  <font-awesome-icon icon="envelope" size="1x" />
                  {{ I18n.t("side_menu.names.notifications") }}
                </a>
                <span class="dropdown-notification-counter" v-if="unopenedNotificationCount > 0">
                  {{ unopenedNotificationCount }}
                </span>
              </div>
              <a class="dropdown-item" href="/users/edit_profile">
                <font-awesome-icon icon="cogs" size="1x" />
                {{ I18n.t("side_menu.names.settings") }}
              </a>
              <a class="dropdown-item" href="/users">
                <font-awesome-icon icon="users" size="1x" />
                {{ I18n.t("side_menu.names.users") }}
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="/users/sign_out">
                <font-awesome-icon icon="sign-out-alt" size="1x" />
                {{ I18n.t("side_menu.names.log_out") }}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <header class="fixed-header mobile-header" v-else-if="side_menu_back_url">
    <div class="container" style="display: flex; align-items: center; position: relative;">
      <div class="back-link">
        <a :href="side_menu_back_url">
          <font-awesome-icon icon="arrow-left" size="lg" />
        </a>
      </div>
      <div style="display: flex; align-items: center; justify-content: center; width: 100%;" v-if="menu_title">
        <div style="font-size: 16px; font-weight: bold;">{{ menu_title }}</div>
      </div>
      <div class="mobile-logo-wrap" v-else>
        <a href="/">
          <div class="mobile-logo"></div>
        </a>
      </div>
    </div>
  </header>
  <header class="fixed-header mobile-header" v-else>
    <div class="container" style="display: flex; align-items: center; position: relative;">
      <div class="btn-hamburger-wrap">
        <div class="btn-hamburger toggle-button"></div>
      </div>
      <span class="notification-counter-hamburger" v-if="unopenedNotificationCount > 0 || (current_user.is_guest && current_user.possible_events_count > 0)"></span>
      <div class="mobile-logo-wrap mobile-logo-wrap-left">
        <a href="/">
          <div class="mobile-logo-link-wrap">
            <div class="mobile-logo-sm"></div>
            <div class="mobile-logo-text">spottySpo</div>
          </div>
        </a>
      </div>
      <div class="new-circle-wrap">
        <a href="/sport_events/new" class="btn cta-btn orange btn-small">
          + {{ I18n.t("sport_events.index.new_button") }}
        </a>
      </div>
    </div>
  </header>
</template>

<script>
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      current_user: Object,
      side_menu_back_url: String,
      menu_title: String
    },
    data() {
      return {
        unopenedNotificationCount: this.current_user.unopened_notifications_count
      }
    },
    mounted() {
      if(!this.current_user.is_guest) {
        this.$cable.subscribe({ channel: "NotificationHeaderChannel", user_id: this.current_user.id })
      }
    },
    channels: {
      NotificationHeaderChannel: {
        connected() {
          console.log('I am connected to NotificationHeaderChannel.')
        },
        rejected() {},
        received(data) {
          console.log("data received", data)

          if (data.unopened_notifications_count) {
            this.unopenedNotificationCount = data.unopened_notifications_count
          }
        },
        disconnected() {

        }
      }
    },
    methods: {
      imgPath() {
        return showAvatar(this.current_user, 46)
      }
    }
  }
</script>

<style scoped>
  .lg-header {
    position: sticky;
  }

  .container {
    max-width: 900px;
  }

  .dropdown-menu {
    font-size: 14px;
  }

  .avatar {
    border-radius: 50%;
  }

  .fixed-header {
    height: 55px;
  }

  .notification-counter-hamburger {
    position: absolute;
    top: 16px;
    left: 27px;
    width: 8px;
    height: 8px;

    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;

    @media only screen and (min-width: 650px) and (max-width: 1256px) {
      left: 42px;
    }
  }

  .desktop-notification-counter {
    position: absolute;
    top: 7px;
    right: 37%;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;
    height: 6px;
  }

  .sport-events-notification-counter {
    position: absolute;
    top: 10px;
    right: 3px;
    background-color: rgba(212, 19, 13, 1);
    height: 6px;
    width: 6px;
    border-radius: 3px;
  }

  .dropdown-notification-counter {
    position: absolute;
    top: -3px;
    right: 23px;

    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;
  }

  .dropdown-notification-wrap {
    position: relative;
  }

  .back-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
  }

  .back-link a{
    width: 30px;
    height: 30px;
    margin-top: 15px;
  }

  .mobile-header {
    height: 51px !important;
    display: flex;
  }

  .desktop-logo-wrap, .mobile-logo-link-wrap {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'Hammersmith One', cursive;
  }

  .desktop-logo {
    background: url("../../images/logo.svg");
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
  }

  .mobile-logo-wrap {
    position: absolute;
    left: 34%;
  }

  .mobile-logo-sm {
    background: url("../../images/logo.svg");
    background-repeat: no-repeat;
    width: 28px;
    height: 28px;
  }

  .mobile-logo {
    background: url("../../images/logo.svg");
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
  }

  .mobile-logo-link-wrap {
  }

  .mobile-logo-wrap-left {
    left: 12% !important;
    bottom: 23% !important;

    @media only screen and (min-width: 768px) and (max-width: 1256px) {
      left: 10% !important;
      bottom: 23% !important;
    }
  }

  .mobile-logo-wrap-left a {
    text-decoration: none;
  }

  .mobile-logo-text {
    text-decoration: none;
    padding-top: 3px;
    font-weight: normal;
  }

  .navbar {
    border-bottom: 0;
    box-shadow: none !important;
    padding: 0 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 58px;
    font-size: .9em;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: 0.2s cubic-bezier(0, 0, 0, 1);
  }

  .navbar-nav {
    flex-direction: row;
    line-height: 1.5;
  }

  .navbar-nav a {
    text-transform: lowercase;
  }

  .nav-item {
    margin-left: 8px;
  }

  .new-circle-wrap {
    bottom: 20%;
    right: 5%;
    position: absolute;
  }

  .cta-btn.yellow {
    background: #f9db85 !important;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .cta-btn.yellow:hover {
    background-color: #f5d270 !important;
  }

  .cta-btn.orange {
    background-color: #f9db85;
    font-weight: 500;
  }

/*  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    font-size: 18px;
    line-height: 40px;
    min-width: 100px;
  }*/

  .cta-btn.orange:hover {
    background-color: #f7d574;
  }

  .btn-small {
    border: 0px !important;
    font-size: 13px !important;
  }

  .create-btn-wrap {
    position: absolute;
    left: 30%;
  }
</style>
