<template>
  <div class="sport-event-review">
    <a class="back-link" :href="`/sport_events/${this.sportEvent.id}`" style="text-decoration: none; margin-bottom: 24px;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div v-if="(this.sportEvent.reviews && this.sportEvent.reviews.length > 0)" class="sport-event__reviews">
      <div class="sport-event__review" v-for="review in this.sportEvent.reviews">
        <a :href="`/users/${review.user_id}`">
          <img :src="avatarPath({ avatar_path: review.avatar })">
        </a>
        {{ review.comment }}
      </div>
    </div>


    <div class="link-wrap">
       <a v-on:click.prevent.stop="openReviewModal()" class="btn cta-btn purple btn-small" v-if="this.sportEvent.request_user_review">
        + {{ I18n.t("sport_events.review.open_review_modal") }}
      </a>
    </div>

    <div class="participants__accepted">
      <div class="participants--title">
        {{ I18n.t("sport_events.review.participants") }}
      </div>

      <div class="participant" v-for="participant in acceptedParticipants">
        <div class="participant__info">
          <div class="participant__avatar">
            <img :src="avatarPath(participant)">
          </div>
          <div class="participant__profile-link">
            <a :href="`/users/${participant.id}`" :class="participantNicknameColorClass(participant)">
              @{{ participant.nickname }}
            </a>
          </div>
          <div :class="logoName(participant.rank)" v-if="(['waiting', 'ready'].includes(sportEvent.status) && user.id == participant.id && !isUserCreator()) || reactedParticipants.includes(participant.id) || canShowParticipantReactions(participant)" style="height: 25px; margin-left: 5px"></div>
        </div>
        <div :class="logoName(participant.rank)" v-if="!(['waiting', 'ready'].includes(sportEvent.status) && user.id == participant.id && !isUserCreator()) && !reactedParticipants.includes(participant.id) && !canShowParticipantReactions(participant)"></div>

        <div v-if="reactedParticipants.includes(participant.id)" style="font-size: 13px; display: flex; align-items: center;" class="reaction-success-message">
          <font-awesome-icon icon="check" />
          {{ I18n.t("sport_events.show.participant_reaction_accepted") }}
        </div>

        <div class="participant__actions" v-if="canShowParticipantReactions(participant)">
          <div class="reactions">
            <div class="like">
              <a href="" v-on:click.prevent.stop="addReaction('like', participant.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 width="475.099px" height="475.099px" viewBox="0 0 475.099 475.099" style="enable-background:new 0 0 475.099 475.099;"
                 xml:space="preserve">
                <path d="M442.829,265.532c9.328-14.089,13.986-29.598,13.986-46.538c0-19.607-7.225-36.637-21.687-51.117
                  c-14.469-14.465-31.601-21.696-51.394-21.696h-50.251c9.134-18.842,13.709-37.117,13.709-54.816c0-22.271-3.34-39.971-9.996-53.105
                  c-6.663-13.138-16.372-22.795-29.126-28.984C295.313,3.09,280.947,0,264.959,0c-9.712,0-18.274,3.521-25.697,10.566
                  c-8.183,7.993-14.084,18.274-17.699,30.837c-3.617,12.559-6.521,24.6-8.708,36.116c-2.187,11.515-5.569,19.652-10.135,24.41
                  c-9.329,10.088-19.511,22.273-30.551,36.547c-19.224,24.932-32.264,39.685-39.113,44.255H54.828
                  c-10.088,0-18.702,3.574-25.84,10.706c-7.135,7.139-10.705,15.752-10.705,25.841v182.723c0,10.089,3.566,18.699,10.705,25.838
                  c7.142,7.139,15.752,10.711,25.84,10.711h82.221c4.188,0,17.319,3.806,39.399,11.42c23.413,8.186,44.017,14.418,61.812,18.702
                  c17.797,4.284,35.832,6.427,54.106,6.427h26.545h10.284c26.836,0,48.438-7.666,64.809-22.99
                  c16.365-15.324,24.458-36.213,24.27-62.67c11.42-14.657,17.129-31.597,17.129-50.819c0-4.185-0.281-8.277-0.855-12.278
                  c7.23-12.748,10.854-26.453,10.854-41.11C445.399,278.379,444.544,271.809,442.829,265.532z M85.949,396.58
                  c-3.616,3.614-7.898,5.428-12.847,5.428c-4.95,0-9.233-1.813-12.85-5.428c-3.615-3.613-5.424-7.897-5.424-12.85
                  c0-4.948,1.805-9.229,5.424-12.847c3.621-3.617,7.9-5.425,12.85-5.425c4.949,0,9.231,1.808,12.847,5.425
                  c3.617,3.617,5.426,7.898,5.426,12.847C91.375,388.683,89.566,392.967,85.949,396.58z M414.145,242.419
                  c-4.093,8.754-9.186,13.227-15.276,13.415c2.854,3.237,5.235,7.762,7.139,13.562c1.902,5.807,2.847,11.087,2.847,15.848
                  c0,13.127-5.037,24.455-15.126,33.969c3.43,6.088,5.141,12.658,5.141,19.697c0,7.043-1.663,14.038-4.996,20.984
                  c-3.327,6.94-7.851,11.938-13.559,14.982c0.951,5.709,1.423,11.04,1.423,15.988c0,31.785-18.274,47.678-54.823,47.678h-34.536
                  c-24.94,0-57.483-6.943-97.648-20.841c-0.953-0.38-3.709-1.383-8.28-2.998s-7.948-2.806-10.138-3.565
                  c-2.19-0.767-5.518-1.861-9.994-3.285c-4.475-1.431-8.087-2.479-10.849-3.142c-2.758-0.664-5.901-1.283-9.419-1.855
                  c-3.52-0.571-6.519-0.855-8.993-0.855h-9.136V219.285h9.136c3.045,0,6.423-0.859,10.135-2.568c3.711-1.714,7.52-4.283,11.421-7.71
                  c3.903-3.427,7.564-6.805,10.992-10.138c3.427-3.33,7.233-7.517,11.422-12.559c4.187-5.046,7.47-9.089,9.851-12.135
                  c2.378-3.045,5.375-6.949,8.992-11.707c3.615-4.757,5.806-7.613,6.567-8.566c10.467-12.941,17.795-21.601,21.982-25.981
                  c7.804-8.182,13.466-18.603,16.987-31.261c3.525-12.66,6.427-24.604,8.703-35.832c2.282-11.229,5.903-19.321,10.858-24.27
                  c18.268,0,30.453,4.471,36.542,13.418c6.088,8.945,9.134,22.746,9.134,41.399c0,11.229-4.572,26.503-13.71,45.821
                  c-9.134,19.32-13.698,34.5-13.698,45.539h100.495c9.527,0,17.993,3.662,25.413,10.994c7.426,7.327,11.143,15.843,11.143,25.553
                  C420.284,225.943,418.237,233.653,414.145,242.419z"/>
                </svg>
              </a>
            </div>
            <div class="dislike">
              <a href="" v-on:click.prevent.stop="openDislikeReportModal(participant.id)">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 width="475.092px" height="475.092px" viewBox="0 0 475.092 475.092" style="enable-background:new 0 0 475.092 475.092;"
                 xml:space="preserve">
                <path d="M442.822,209.562c1.715-6.283,2.57-12.847,2.57-19.702c0-14.655-3.621-28.361-10.852-41.112
                  c0.567-3.995,0.855-8.088,0.855-12.275c0-19.223-5.716-36.162-17.132-50.819v-1.427c0.191-26.075-7.946-46.632-24.414-61.669
                  C377.387,7.521,355.831,0,329.186,0h-31.977c-19.985,0-39.02,2.093-57.102,6.28c-18.086,4.189-39.304,10.468-63.666,18.842
                  c-22.08,7.616-35.211,11.422-39.399,11.422H54.821c-10.088,0-18.702,3.567-25.84,10.704C21.845,54.387,18.276,63,18.276,73.085
                  v182.728c0,10.089,3.566,18.698,10.705,25.837c7.142,7.139,15.752,10.705,25.84,10.705h78.228
                  c6.849,4.572,19.889,19.324,39.113,44.255c11.231,14.661,21.416,26.741,30.551,36.265c3.612,3.997,6.564,10.089,8.848,18.271
                  c2.284,8.186,3.949,16.228,4.998,24.126c1.047,7.898,3.475,16.516,7.281,25.837c3.806,9.329,8.944,17.139,15.415,23.422
                  c7.423,7.043,15.985,10.561,25.697,10.561c15.988,0,30.361-3.087,43.112-9.274c12.754-6.184,22.463-15.845,29.126-28.981
                  c6.663-12.943,9.996-30.646,9.996-53.103c0-17.702-4.568-35.974-13.702-54.819h50.244c19.801,0,36.925-7.23,51.394-21.7
                  c14.469-14.462,21.693-31.497,21.693-51.103C456.809,239.165,452.15,223.652,442.822,209.562z M85.942,104.219
                  c-3.616,3.615-7.898,5.424-12.847,5.424c-4.95,0-9.233-1.805-12.85-5.424c-3.615-3.621-5.424-7.898-5.424-12.851
                  c0-4.948,1.809-9.231,5.424-12.847c3.621-3.617,7.9-5.424,12.85-5.424c4.949,0,9.231,1.807,12.847,5.424
                  c3.617,3.616,5.426,7.898,5.426,12.847C91.368,96.317,89.56,100.598,85.942,104.219z M409.135,281.377
                  c-7.42,7.33-15.886,10.992-25.413,10.992H283.227c0,11.04,4.564,26.217,13.698,45.535c9.138,19.321,13.71,34.598,13.71,45.829
                  c0,18.647-3.046,32.449-9.134,41.395c-6.092,8.949-18.274,13.422-36.546,13.422c-4.951-4.948-8.572-13.045-10.854-24.276
                  c-2.276-11.225-5.185-23.168-8.706-35.83c-3.519-12.655-9.18-23.079-16.984-31.266c-4.184-4.373-11.516-13.038-21.982-25.98
                  c-0.761-0.951-2.952-3.806-6.567-8.562c-3.614-4.757-6.613-8.658-8.992-11.703c-2.38-3.046-5.664-7.091-9.851-12.136
                  c-4.189-5.044-7.995-9.232-11.422-12.565c-3.427-3.327-7.089-6.708-10.992-10.137c-3.901-3.426-7.71-5.996-11.421-7.707
                  c-3.711-1.711-7.089-2.566-10.135-2.566h-9.136V73.092h9.136c2.474,0,5.47-0.282,8.993-0.854c3.518-0.571,6.658-1.192,9.419-1.858
                  c2.76-0.666,6.377-1.713,10.849-3.14c4.476-1.425,7.804-2.522,9.994-3.283c2.19-0.763,5.568-1.951,10.138-3.571
                  c4.57-1.615,7.33-2.613,8.28-2.996c40.159-13.894,72.708-20.839,97.648-20.839h36.542c16.563,0,29.506,3.899,38.828,11.704
                  c9.328,7.804,13.989,19.795,13.989,35.975c0,4.949-0.479,10.279-1.423,15.987c5.708,3.046,10.231,8.042,13.559,14.987
                  c3.333,6.945,4.996,13.944,4.996,20.985c0,7.039-1.711,13.61-5.141,19.701c10.089,9.517,15.126,20.839,15.126,33.974
                  c0,4.759-0.948,10.039-2.847,15.846c-1.899,5.808-4.285,10.327-7.139,13.562c6.091,0.192,11.184,4.665,15.276,13.422
                  c4.093,8.754,6.14,16.468,6.14,23.127C420.277,265.525,416.561,274.043,409.135,281.377z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
     <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAvatar from "../../utils/avatar"
  import SportEventDislikeForm from "../../components/sport_events/modals/dislike_report.vue"
  import SportEventReviewForm from "../../components/sport_events/modals/review.vue"

  export default {
    props: {
      creator: Object,
      user: Object,
      sport_event: Object,
    },
    data() {
      return {
        participantInActionId: null,
        reactedParticipants: [],
        currentReactionProcessed: {},
        sportEvent: this.sport_event,
      }
    },
    created() {
      this.$eventHub.$on('change-sport-event', this.resetSportEvent)
    },
    mounted() {
      if (this.sportEvent.request_user_review && this.sportEvent.reviews.length == 0)
        this.openReviewModal()
    },
    methods: {
      creatorProfileLink() {
        return `/users/${this.creator.id}`
      },
      avatarPath(user) {
        return showAvatar(user, 50)
      },
      resetSportEvent(emmittedSportEvent) {
        this.sportEvent = emmittedSportEvent
      },
      isUserCreator() {
        return this.creator.id == this.user.id
      },
      canShowParticipantReactions(participant) {
        return this.sportEvent.status == 'finished' &&
               this.participantIds.includes(this.user.id) &&
               this.user.id != participant.id &&
               !_.map(this.sportEvent.reactions, 'for_user_id').includes(participant.id)
      },
      skipSubscribersHighlight(val) {
        this.sportEvent.share_link_highlight = false
      },
      resetSportEvent(emmittedSportEvent) {
        this.sportEvent = emmittedSportEvent
      },
      addReaction(reaction, participantId) {
        if (this.currentReactionProcessed[participantId])
          return

        this.currentReactionProcessed[participantId] = 1

        axios
            .post(`/api/v1/user_sport_event_reactions/`,
              {
                user_sport_event_reaction: {
                  for_user_id: participantId,
                  sport_event_id: this.sportEvent.id,
                  reaction: reaction
                }
              }
            )
            .then(response => {
              this.sportEvent = response.data["sport_event"]
              this.reactedParticipants.push(participantId)

              delete this.currentReactionProcessed[participantId]
            })
            .catch(error => {
              console.log(error)

              delete this.currentReactionProcessed[participantId]
            })
      },
      participantMessage(participantId) {
        return this.sportEvent.participation_requests_messages[participantId]
      },
      logoName(rank) {
        return `${rank}-medal`
      },
      fullName(user) {
        if (!user.name && !user.surname) {
          return
        }

        return `${this.user.name ? this.user.name : ''} ${this.user.surname ? this.user.surname : ''}`;
      },
      participantNicknameColorClass(user) {
        if (user.id == this.creator.id) {
          return "gold-nickname"
        }
      },
      openDislikeReportModal(reportedUserId) {
        this.$modal.show(SportEventDislikeForm,
          {
            sportEventId: this.sportEvent.id,
            reportedUserId: reportedUserId,
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openReviewModal() {
        this.$modal.show(SportEventReviewForm,
          {
            sportEventId: this.sportEvent.id,
            isCreator: this.isUserCreator()
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600),
            height: "auto"
          }
        )
      },
    },
    computed: {
      requestsFromParticipants() {
        return this.sportEvent.participation_requests
      },
      requestsFromCreator() {
        return this.sportEvent.participants_requests
      },
      acceptedParticipants() {
        return this.sportEvent.accepted_participants
      },
      participantIds() {
        return _.map(this.acceptedParticipants, "id")
      },
    }
  }
</script>

<style scoped>
  .sport-event-review {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .link-wrap {
    margin-top: 24px;

    @media only screen and (max-width: 600px) {
      margin-top: 24px;
    }
  }

  .participants--title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;

     @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .participants {
    text-align: center;
    margin-top: 15px;
  }

  .participant {
    display: flex;
    margin: 10px 0px;
    justify-content: space-between;
    padding: 5px 0px;

    @media only screen and (min-width: 600px) {
      border-bottom: 1px solid #dcdbdb;
    }
  }

  .participants__accepted {
    margin: 24px 0px;
  }

  .participant__avatar img {
    border-radius: 30px;
    width: 30px;
    height: 30px;
  }

  .participant__profile-link {
    margin-left: 5px;
  }

  .participant__info {
    display: flex;
    align-items: center;
  }

  .participant__actions .reactions {
    display: flex;
  }

  .participant__actions .reactions .like {
    margin-right: 10px;
  }

  .participant__actions .reactions .like svg {
    width: 25px;
    height: 25px;
  }

  .participant__actions .reactions .dislike svg {
    width: 20px;
    height: 20px;
  }

  .sport-event__reviews {
    background-color: #f4f6f8;
    border-radius: 10px;
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-bottom: 24px;

    @media only screen and (min-width: 600px) {
      font-size: 13px;
      margin-top: 24px;
    }
  }

  .sport-event__review {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin-top: 8px;
    margin-right: 8px;
  }

  .sport-event__review img {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }

  .reaction-success-message svg {
    margin-right: 3px;
    color: green;
  }

  .btn {
    color: white !important;
  }

  .btn-small {
    border: 0px !important;
    font-size: 13px !important;
  }
</style>
