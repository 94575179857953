<template>
  <nav id="menu">
    <div v-if="$mq !== 'lg'">
      <div class="profile" v-if="!current_user.is_guest">
        <div class="avatar">
          <img :src="imgPath()">
        </div>
        <div class="user-name">{{ current_user.nickname }}</div>
        <div class="user-take-part-count">
          {{ I18n.t('side_menu.events_participation_count', { count: current_user.successful_events_count }) }}
        </div>
        <div class="sports-tags"></div>
      </div>
      <div class="registration-wrap" v-else>
        <div class="registration-link-wrap">
          <a href="/users/sign_in" class="registration-link sign-in-link">
            {{ I18n.t("side_menu.names.sign_in") }}
          </a>
        </div>

        <div class="registration-link-wrap">
          <a href="/users/sign_up" class="registration-link sign-up-link">
            {{ I18n.t("side_menu.names.sign_up") }}
          </a>
        </div>
      </div>

      <div class="menu-list">
        <ul>
          <li v-if="!current_user.is_guest">
            <span>
              <font-awesome-icon icon="home" />
            </span>
            <a href="/">
              {{ I18n.t("side_menu.names.main") }}
            </a>
          </li>
          <li>
            <span>
              <font-awesome-icon icon="search" />
            </span>
            <a href="/sport_events" style="position: relative;">
              {{ I18n.t("side_menu.names.events") }}
              <span class="notification-counter" v-if="current_user.possible_events_count > 0">
                {{ current_user.possible_events_count }}
              </span>
            </a>
          </li>
          <li v-if="!current_user.is_guest">
            <span>
              <font-awesome-icon icon="envelope" />
            </span>
            <a href="/notifications" style="position: relative;">
              {{ I18n.t("side_menu.names.notifications") }}
              <span class="notification-counter" v-if="current_user.unopened_notifications_count > 0">
                +
              </span>
            </a>
          </li>
          <li>
            <span>
              <font-awesome-icon icon="users" />
            </span>
            <a href="/users">
              {{ I18n.t("side_menu.names.users") }}
            </a>
          </li>
          <li>
            <span>
              <font-awesome-icon icon="info-circle" />
            </span>
            <a href="/pages/about">
              {{ I18n.t('side_menu.names.about') }}
            </a>
          </li>
          <li v-if="!current_user.is_guest">
            <span>
              <font-awesome-icon icon="sign-out-alt" />
            </span>
            <a href="/users/sign_out">
              {{ I18n.t("side_menu.names.log_out") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      current_user: Object,
    },
    methods: {
      imgPath() {
        return showAvatar(this.current_user, 46)
      }
    }
  }
</script>

<style scoped>
  #menu {
    height: 100%;
    color: #2D2D2B;
    border-right: 1px solid #f3f3f3;
  }

  .profile {
    height: 30%;
    text-align: center;

    font-size: 15px;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .avatar {
    margin-top: 30px;
  }

  .user-name {
    font-size: 20px;
    font-weight: bold;
    margin: 15px;
  }

  .avatar img {
    object-fit: cover;
    border-radius:50%;
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    -webkit-box-shadow: 0 0 0 3px #fff;
    -moz-box-shadow: 0 0 0 3px #fff;
    box-shadow: 0 0 0 3px #fff;
  }

  .menu-list {
    margin-top: 20px;
  }

  .menu-list ul {
    list-style: none;
    -webkit-padding-start: 35px;
  }

  .menu-list li {
    padding: 12px 0px;
  }

  .menu-list li span svg {
    width: 20px;
  }

  .menu-list li a {
    font-size: 15px;
    font-weight: 550;
    margin-left: 5px;
  }

  .notification-counter {
    position: absolute;
    right: -13px;
    top: -5px;

    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 2px 3px;
    font: 8px Verdana;
  }

  .registration-wrap {
    height: 120px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
  }

  .registration-link-wrap {
    height: 100%;
  }

  .registration-link {
    width: 100%;
    background: #6460ac;
    color: #fff;
    font-size: .9em;
    text-align: center;
    padding: 12px 18px;
  }

  .sign-in-link {
    background: #81a65a;;
  }
</style>
