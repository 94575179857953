<template>
  <div class="new-competition">
    <a class="back-link" :href="`/users/${this.compete_with_user.nickname}`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div class="competition-title" v-if="$mq != 'sm'">Новое соревнование с @{{ compete_with_user.nickname }}</div>

    <div class="errors" v-if="this.errors">
      {{ this.errors }}
    </div>

    <div style="margin-top: 24px;">
      <vue-form-generator :model="localCompetition"
                          :schema="this.formSchema"
                          :options="this.formOptions"
                          ref="formSchema"
                          />
    </div>

    <div style="padding-top: 24px; text-align: center;" v-if="loading">
      <img src="../../images/loading.gif" style="width: 80px;">
    </div>

    <div class="submit-button-wrap" v-else>
      <a class="btn cta-btn purple btn-sm" v-on:click.prevent="submitAction()">
        {{ I18n.t("competitions.new_form.buttons.submit") }}
      </a>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import { validators } from "vue-form-generator"
  import axios from "axios"
  import showAlert from "../../utils/alert"

  export default {
    props: {
      compete_with_user: Object,
      sports: Array,
    },
    data: function() {
      return {
        errors: null,
        loading: false,
        localCompetition: { count_of_wins: 5 },
        formSchema: {
          fields: [
            {
              type: "select",
              label: I18n.t("competitions.new_form.labels.sport"),
              model: "sport_id",
              values: this.sports,
              styleClasses: "col-xs-6",
              required: true,
              validator: validators.number,
              selectOptions: {
                name: "name",
                noneSelectedText: I18n.t("competitions.new_form.non_selected_text.sport"),
              },
            },
            {
              type: "input",
              label: I18n.t("competitions.new_form.labels.count_of_wins"),
              inputType: "string",
              model: "count_of_wins",
              styleClasses: "col-xs-6",
              required: true,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("competitions.new_form.non_selected_text.count_of_wins"),
              },
            },
          ]
        },
        formOptions: {
          validationErrorClass: "has-error",
          validationSuccessClass: "has-success",
          validateAfterChanged: true,
        },
      }
    },
    methods: {
      submitAction() {
        this.errors = null

        axios
          .post("/sport_competitions/",
            {
              sport_competition: {
                sport_id: this.localCompetition.sport_id,
                requested_user_id: this.compete_with_user.id,
                count_of_wins: this.localCompetition.count_of_wins
              }
            }
          )
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))

            Turbolinks.visit(`/users/${this.compete_with_user.nickname}`)
            // redirect to user and show there info that waiting request from user
          })
          .catch(error => {
            this.errors = error.response.data[0]
            console.log(error)
          })
      }
    }
  }
</script>

<style scoped>
  .new-competition {
    font-size: 14px;
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 60px;
      font-size: 13px;
    }
  }

  .errors {
    color: red;
  }

  .competition-title {
    font-size: 16px;
    font-weight: bold;

    @media only screen and (min-width: 600px) {
      margin-top: 24px;
    }
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.purple {
    background-color: #6460ac;
    color: #fff;
  }

  .cta-btn.purple:hover {
    background-color: #5b459c;
    color: #fff;
  }

  .submit-button-wrap {
    margin-top: 24px;
  }
</style>
