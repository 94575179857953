import axios from "axios"

export default function(user) {
  const vapidPublicKey = new Uint8Array(JSON.parse(document.body.dataset.vapidPublicKey));

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    console.log("Permission to receive notifications has been granted");
  }
  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied' && user.ready_for_webpush_request && (!detectIOS() || window.navigator.standalone)) {
    axios.post("/api/v1/request_webpush_subscriptions").then(response => {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            serviceWorkerRegistration.pushManager.getSubscription()
            .then((subscription) => {

              if (subscription) {
                return subscription;
              }

              return serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapidPublicKey
              });
            }).then((subscription) => {
              axios.post("/api/v1/webpush_subscriptions", { subscription: subscription.toJSON(), requested_from_os: detectOS() })
            })
          });
        }
      });
    })
    .catch(error => {
    })
    .then(() => {
    })
  }

  else {
    console.log("Don't know what to do :(");
  }
}

function detectIOS() {
  const platform = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPhone|iPod/.test(navigator.platform) ||
    (/Mac/.test(platform) && navigator.maxTouchPoints > 0) || // For iPad Pro
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0); // For newer iOS devices
}

function detectOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection
  if (detectIOS()) return 'ios';

  // Android detection
  if (/android/i.test(userAgent)) return 'android';

  // Windows detection
  if (/windows/i.test(userAgent)) return 'windows';

  // macOS detection
  if (/mac os/i.test(userAgent)) return 'macos';

  // Linux detection
  if (/linux/i.test(userAgent)) return 'linux';

  return 'unknown';
}
